/* eslint-disable camelcase */
import { API_ROOT } from 'config';

import base from './base';

export interface UserAnalyticsProfile {
  id: number;
  company: string;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  hris_employee_id?: string | null;
  visibility_type: string;
  population_visibility_type: string;
  field_visibility_type: string;
  account_type: string;
  is_admin: boolean;
  profile_picture_url: string;
  is_cpo: boolean;
  is_guest: boolean;
  can_share_report: boolean;
  user_hash: string;
  front_chat_hash: string;
  has_access_to_cpohub: boolean;
  has_access_to_cpohub_network: boolean;
  has_org_chart_freemium_access: boolean;
  has_hard_disable_feature_flag: boolean;
  has_analytics_enabled: boolean;
  has_complete_profile: boolean;
  date_joined: string;
  company_root_nodes: string[];
  company_json: {
    [key: string]: unknown;
    configurations?: {
      color_schemes: string[];
      repeat_colors?: boolean;
    };
    fiscal_year_start_month: number;
    current_hris?: string;
  };
}

const getUser = async () => {
  const response = await base.api.get<null, UserAnalyticsProfile>(`${API_ROOT}/api/users/me/`);
  return response;
};

export default {
  getUser,
};
